<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :title="dialogTitle"
      width="500px"
      v-drag
      @closed="dialogClosed"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        ref="elform"
        :model="formData"
        label-width="150px"
        size="small"
        :rules="rules"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="货品估值上限（含）"
              prop="maxAssessment"
            >
              <el-input-number
                v-model="formData.maxAssessment"
                :min="formData.minAssessment"
                label="描述文字"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="货品估值下限"
              prop="minAssessment"
            >
              <el-input-number
                v-model="formData.minAssessment"
                :min="0"
                :max="formData.maxAssessment"
                label="描述文字"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="可寄存价值比例"
              prop="consignPriceRatio"
            >
              <el-input
                placeholder="请输入比例"
                v-model.trim="formData.consignPriceRatio"
                @input="numberCtrol(formData, 'consignPriceRatio', 2, 3)"
              >
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="寄存费比例（7天）"
              prop="consignFeeRatio"
            >
              <el-input
                placeholder="请输入比例"
                v-model.trim="formData.consignFeeRatio"
                @input="numberCtrol(formData, 'consignFeeRatio', 2, 3)"
              >
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          size="small"
          @click="dialogVisible = false"
        >取消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="submitForm"
          :loading="loading"
        >保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import utils, { numberCtrol } from '@/utils/utils';
import api from '@/api/ValuationAttrApi';

export default {
  name: 'AddValuationAttr',
  data() {
    return {
      dialogVisible: false,
      dialogTitle: '添加估值参数配置',
      loading: false,
      formData: {
        maxAssessment: 0,
        minAssessment: 0,
        consignPriceRatio: '',
        consignFeeRatio: '',
        id: '',
      },
      rules: {
        maxAssessment: [{ required: true, message: '请输入', trigger: 'change' }],
        minAssessment: [{ required: true, message: '请输入', trigger: 'change' }],
        consignPriceRatio: [{ required: true, validator: utils.scaleVerify, trigger: 'change' }],
        consignFeeRatio: [{ required: true, validator: utils.scaleVerify, trigger: 'change' }],
      },
    };
  },
  watch: {},
  methods: {
    numberCtrol,
    submitForm() {
      this.$refs.elform.validate(valid => {
        if (valid) {
          this.loading = true;
          const {
            maxAssessment,
            minAssessment,
            id,
          } = this.formData;
          let { consignPriceRatio, consignFeeRatio, creatorId, creatorName } = this.formData;
          consignPriceRatio /= 100;
          consignFeeRatio /= 100;
          creatorId = this.$store.state.login.userInfo.userId;
          creatorName = this.$store.state.login.userInfo.userName;
          if (this.dialogTitle === '添加估值参数配置') {
            api.addAssessmentParameter(
              {
                id,
                maxAssessment,
                minAssessment,
                consignPriceRatio,
                consignFeeRatio,
                creatorId,
                creatorName,
              },
            )
              .then(() => {
                this.dialogVisible = false;
                this.$emit('refreshTable');
              })
              .catch(err => {
                console.log(err);
                this.$message.error(err.message);
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            api.modifyAssessmentParameter(
              {
                id,
                maxAssessment,
                minAssessment,
                consignPriceRatio,
                consignFeeRatio,
                creatorId,
                creatorName,
              },
            )
              .then(() => {
                this.dialogVisible = false;
                this.$emit('refreshTable');
              })
              .catch(err => {
                console.log(err);
                this.$message.error(err.message);
              })
              .finally(() => {
                this.loading = false;
              });
          }
        }
      });
    },
    dialogClosed() {
      this.$refs.elform.resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
