<template>
  <div>
    <l-toggle :FormButton="FormButton" :openFormBtnShow="false"></l-toggle>
    <div class="tableBox">
      <el-table
        ref="eltable"
        :data="tableData"
        :header-cell-style="{background:'#f2f2f2'}"
        height="100%"
        stripe
        border
        v-loading="tableLoading"
        @selection-change="handleSelectionChange"
      >
        <template slot="empty">
          <img src="@/assets/img/table_noData.png" width="300" height="300" />
        </template>
        <el-table-column type="selection" width="50" align="center"></el-table-column>
        <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>

        <el-table-column prop="minAssessment" label="货品估值下限" align="center"></el-table-column>
        <el-table-column prop="maxAssessment" label="货品估值上限（含）" align="center"></el-table-column>
        <el-table-column prop="consignPriceRatio" label="可寄存价值比例" align="center"></el-table-column>
        <el-table-column prop="consignFeeRatio" label="寄存费比例（7天）" align="center" width="150"></el-table-column>

        <el-table-column label="操作" align="center" fixed="right" width="200">
          <template slot-scope="scope">
            <el-button
              type="success"
              icon="el-icon-edit"
              size="mini"
              @click="edit(scope.row)"
            >编辑
            </el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="del(scope.row)"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px;"
        background
        :disabled="tableLoading"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.pageNum"
        :page-sizes="[30, 50, 100]"
        :page-size="30"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
      >
      </el-pagination>
      <div class="tableFootBtn">
        <!-- <el-button
          type="danger"
          size="mini"
          icon="el-icon-delete"
        >删除选择
        </el-button> -->
      </div>
    </div>
    <!-- 新增层 -->
    <add-valuation-attr
      ref="AddValuationAttr"
      @refreshTable="refreshTable"
    ></add-valuation-attr>
    <!-- 估价计算器 -->
    <valuation-calc
      ref="ValuationCalc"
      @refreshTable="refreshTable"
    ></valuation-calc>
  </div>
</template>

<script>
import LToggle from '@/components/LToggle.vue';
import AddValuationAttr from './ValuationAttr/AddValuationAttr.vue';
import ValuationCalc from './ValuationAttr/ValuationCalc.vue';
import api from '@/api/ValuationAttrApi';

export default {
  name: 'ValuationAttr',
  components: {
    LToggle,
    AddValuationAttr,
    ValuationCalc,
  },
  data() {
    return {

      FormButton: [
        {
          icon: 'el-icon-plus',
          name: '添加估值参数配置',
          type: 'primary',
          click: this.addEvent,
        },
        // {
        //   icon: 'iconfont icon-jisuanqi',
        //   name: '估价计算器',
        //   type: 'primary',
        //   click: this.valuationCalc,
        // },
      ],
      tableLoading: false,
      tableData: [],
      selectedTableData: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 30,
        total: 0,
      },
    };
  },
  watch: {
    tableLoading(nval) {
      this.FormButton[0].loading = nval;
    },
  },
  created() {
    this.reqTableData();
  },
  methods: {
    del(row) {
      this.$confirm('确定要<span class="dangerColor">删除</span>吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        dangerouslyUseHTMLString: true,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = '正在删除';
            api.deleteAssessmentParameter(row.id)
              .then(() => {
                instance.confirmButtonLoading = false;
                this.$message.success('删除成功!');
                this.refreshTable();
              })
              .catch(err => {
                this.$message.error(err.message);
              })
              .finally(() => {
                done();
              });
          } else {
            done();
          }
        },
      }).then(() => {}).catch(() => {});
    },
    edit(row) {
      this.$refs.AddValuationAttr.dialogVisible = true;
      this.$refs.AddValuationAttr.dialogTitle = '修改估值参数配置';
      this.$nextTick(() => {
        this.$refs.AddValuationAttr.formData = { ...row };
      });
    },
    searchEvent() {
      this.reqTableData();
    },
    addEvent() {
      this.$refs.AddValuationAttr.dialogVisible = true;
      this.$refs.AddValuationAttr.dialogTitle = '添加估值参数配置';
    },
    valuationCalc() {
      this.$refs.ValuationCalc.dialogVisible = true;
    },
    refreshTable() {
      this.pageInfo.pageNum = 1;
      this.reqTableData();
    },
    reqTableData() {
      this.tableLoading = true;
      const {
        pageNum,
        pageSize,
      } = this.pageInfo;
      api.getAssessmentParameterPageList(
        {
          pageNum,
          pageSize,
        },
      )
        .then(res => {
          this.tableData = res.result.datas;
          this.pageInfo.total = res.result.totalRecords;
        })
        .catch(err => {
          console.log(err);
          this.$message.error(err.message);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    handleSelectionChange(select) {
      this.selectedTableData = select;
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.reqTableData();
    },
    handleCurrentChange(val) {
      this.pageInfo.pageNum = val;
      this.reqTableData();
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
